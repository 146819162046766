import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import { TextVariant } from '@naf/text';
import useSelector from '../../../redux/typedHooks';
import { formattedPrice } from '../../../lib/formattedPrice';
import { defaultProduct } from '../forms/drivingPermit';
import S from './styles';

interface Props {
  products?: { productId: string; amount: string };
}

export const Summary = ({ products }: Props) => {
  const { productId, amount } = products || { productId: '', amount: '1' };
  const product = useSelector(
    (state) =>
      state.internationalDrivingPermit.data.eligibleProducts &&
      state.internationalDrivingPermit.data.eligibleProducts[productId],
  );
  const price = product && product.productAppPrice && product.productAppPrice[amount];
  const user = useSelector((state) => state.myMembership.customerInformation.data);
  const isMember = user?.membership?.isValid ?? false;

  return product ? (
    <S.SummaryWrapper>
      <S.Summary>
        <S.SummaryHeading variant={TextVariant.Header3}>Oppsummering</S.SummaryHeading>
        <S.LineWrapper>
          <S.LineText variant={TextVariant.ArticleTextHeader}>{product.productName}</S.LineText>
          <S.Price variant={TextVariant.ArticleTextHeader}>{price && formattedPrice(price.itemTotalPrice)} kr</S.Price>
        </S.LineWrapper>
        <S.LineWrapper $withTopBorder>
          <S.LineText variant={TextVariant.ArticleTextHeader}>Totalt</S.LineText>
          <S.TotalPrice variant={TextVariant.SubHeader}>
            {price && formattedPrice(price.itemTotalPrice)} kr
          </S.TotalPrice>
        </S.LineWrapper>
        {isMember && <S.LineText variant={TextVariant.Small}>Din medlemspris!</S.LineText>}
      </S.Summary>
    </S.SummaryWrapper>
  ) : null;
};

export const SummaryProvider = ({ control: formController }: { control: Control }) => {
  const products = useWatch({
    control: formController,
    name: 'product', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: defaultProduct, // default value before the render
  });

  return <Summary products={products} />; // only re-render at the component level, when firstName changes
};
